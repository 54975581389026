import React from "react";
import { object, shape } from "prop-types";
import { LazyCollection, LazyLoadImages, replaceAllStoriesInCollection, WithPreview } from "@quintype/components";

import TaboolaScript from "../ads/taboola-ad/load-taboola-ad";
import TaboolaAd from "../ads/taboola-ad";
import MobileCollection from "../collection-templates/mobile-collection";

import { getCollectionTemplate } from "../get-collection-template";

export const HomePage = ({ data }) => {
  // eslint-disable-next-line no-unused-vars

  const filterCollections = data?.collection?.items?.filter((i) => i.items.length);
  const collection1 = { ...data?.collection };
  collection1.items = [filterCollections && filterCollections[0]];
  const remainingCollection = { ...data?.collection };
  remainingCollection.items = filterCollections?.slice(1);

  return (
    <div className="container">
      <TaboolaScript type="homepage" />
      <LazyLoadImages>
        <LazyCollection
          minHeight={0}
          collection={data?.collection}
          collectionTemplates={getCollectionTemplate}
          lazyAfter={2}
          className="lazy-collection"
        />
      </LazyLoadImages>

      <TaboolaAd container="taboola-below-home-thumbnails" placement="Below Home Thumbnails" mode="thumbnails-d" />
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
