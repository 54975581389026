import React, { useState, useEffect } from "react";
import { Link } from "@quintype/components";

import "./budget-floating-widget.m.css";

function BudgetFloatingWidget() {
  const [showWidgetButton, setShowWidgetButton] = useState(true);
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  return (
    <>
      {showWidgetButton && (
        <div styleName="budget-toggle-widget-wrapper" className="budget-floating-widget">
          <div styleName="button-and-close-wrapper">
            <span
              styleName="close-widget-button"
              onClick={(e) => {
                e.stopPropagation();
                setShowWidgetButton(false);
              }}
            >
              &times;
            </span>
            <Link
              styleName="flip-image-wrapper"
              href="https://www.prajavani.net/business/budget?utm_source=UB2025-FW&utm_medium=UB2025-FW&utm_campaign=UB2025-FW"
            >
              <div styleName="flip"></div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default BudgetFloatingWidget;
